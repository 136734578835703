import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ResponseFailModal, ResponseSuccessModal } from '../../components/Commons/ModalResponse';
import { useRefresh } from '../../lib/useRefresh';
import { RootState } from '../../stores';
import { PaymentCancelContainerProps } from '../../types/commons';
import { initialize as initializeHeader } from '../../stores/header';
import { changeResult, cancelPaymentAction } from '../../stores/paymentList';
import PaymentCancel from '../../components/Service/PaymentCancel';

const ModalWrap = styled(Modal)`
  .modal-dialog {
    max-width: 500px;
  }
`;

const PaymentCancelContainer = ({
  isOpen,
  hotelCodeItem,
  confirmationNoItem,
  reasonItem,
  toggle,
  reload,
}: PaymentCancelContainerProps) => {
  const dispatch = useDispatch();
  const { paymentCancelResponse } = useSelector(({ paymentList }: RootState) => ({
    paymentCancelResponse: paymentList.paymentCancelResponse,
  }));
  const [popupMessage, setPopupMessage] = useState<string>('');
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler()),
    [],
  );

  const handleDelete = () => {
    handleRefresh(() =>
      dispatch(
        cancelPaymentAction({
          hotelCode: hotelCodeItem,
          confirmationNo: confirmationNoItem,
          cancelReason: reasonItem,
        }),
      ),
    );
  };

  useEffect(() => {
    if (!paymentCancelResponse) return;
    const { resultCode, msg } = paymentCancelResponse;

    // 결제 취소 성공
    if (resultCode === '1000') {
      setPopupMessage('결제 취소가 완료 되었습니다.');
      setSuccessModal(true);

      setTimeout(() => {
        setSuccessModal(false);
        toggle();
        reload && reload();
      }, 1500);
    }

    // 결제 취소 실패
    if (
      resultCode === 'MC003' ||
      resultCode === 'MC004' ||
      resultCode === 'MC005' ||
      resultCode === 'MC006' ||
      resultCode === 'MC007' ||
      resultCode === 'MC008'
    ) {
      dispatch(initializeHeader());
      setPopupMessage('세션이 만료 되었습니다.');
      setErrorModal(true);
    }
    if (resultCode === '9999') {
      setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
      setErrorModal(true);
    }
    if (resultCode && resultCode !== '1000') {
      setPopupMessage(msg);
      setErrorModal(true);
    }

    dispatch(changeResult({ key: 'paymentCancelResponse', value: undefined }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentCancelResponse]);

  return (
    <>
      <ModalWrap show={isOpen} onHide={() => toggle()} centered backdrop="static">
        <ModalBody className="m-3">
          <PaymentCancel toggle={toggle} handleDelete={handleDelete} />
        </ModalBody>
      </ModalWrap>
      {errorModal && (
        <ResponseFailModal
          isOpen={errorModal}
          toggle={() => setErrorModal(!errorModal)}
          message={popupMessage}
        />
      )}
      {successModal && (
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />
      )}
    </>
  );
};

export default PaymentCancelContainer;
