import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Table from '../Commons/Table';
import { dateFormatter, priceFormatter, statusFormatter } from '../../lib/formatter';
import { PaymentDetailProps } from '../../types/payment';

/* styled */
const FormCardWrap = styled.div`
  width: 100%;
  margin: auto;
  border-radius: 0.3rem;

  .popup-wrap {
    display: flex;
    padding: 1rem 1.25rem 1.875rem 1.25rem;
    flex-direction: column;
    align-items: center;

    .form-table {
      width: 100%;
      height: 450px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }
  }
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: flex-start;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
      margin: 0 5px;
      font-size: 0.875rem;
      color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;

const PaymentDetail = ({ paymentDetailItems, toggle, handleSyncSaga }: PaymentDetailProps) => {
  return (
    <FormCardWrap id="room-create">
      <FormCardTitle>
        <h1>결제 이력</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()} />
      </FormCardTitle>
      <div className="popup-wrap">
        <div className="form-table">
          <Table
            columns={[
              {
                key: 'lastName',
                text: '예약자 성',
                width: '5%',
              },
              {
                key: 'firstName',
                text: '예약자 이름',
                width: '5%',
              },
              {
                key: 'guestName',
                text: '투숙객 한글명',
                width: '5%',
              },
              {
                key: 'reservationStatusText',
                text: '예약 상태',
                width: '5%',
              },
              {
                key: 'roomNumber',
                text: '객실 번호',
                width: '5%',
              },
              {
                key: 'arrivalDate',
                text: '체크인',
                width: '7%',
              },
              {
                key: 'departureDate',
                text: '체크아웃',
                width: '7%',
              },
              {
                key: 'cardName',
                text: '카드 종류',
                width: '7%',
              },
              {
                key: 'applyNum',
                text: '승인 번호',
                width: '9%',
              },
              {
                key: 'amount',
                text: '거래 금액 KRW',
                width: '10%',
                formatter: {
                  func: priceFormatter,
                  params: ['amount'],
                },
              },
              {
                key: 'paymentDate',
                text: '마지막 거래 일시',
                width: '8%',
                formatter: {
                  func: dateFormatter,
                  params: ['paymentDate'],
                },
              },
              {
                key: 'requestName',
                text: '요청자',
                width: '5%',
              },
              {
                key: 'channel',
                text: '승인 기기',
                width: '4%',
              },
              {
                key: 'pgType',
                text: 'PG 종류',
                width: '4%',
              },
              {
                key: 'status',
                text: '결제 상태',
                width: '4%',
                formatter: {
                  func: (paymentStatus: string, paymentStatusText: string) =>
                    statusFormatter(paymentStatus, paymentStatusText),
                  params: ['paymentStatus', 'paymentStatusText'],
                },
              },
              {
                key: 'button2',
                text: '',
                width: '10%',
                items: [
                  {
                    text: '동기화',
                    action: {
                      func: (hotelCode: string, confirmationNo: string) =>
                        handleSyncSaga(hotelCode, confirmationNo),
                      params: ['hotelCode', 'confirmationNo'],
                    },
                  },
                ],
              },
            ]}
            datas={paymentDetailItems}
          />
        </div>
      </div>
    </FormCardWrap>
  );
};

export default PaymentDetail;
