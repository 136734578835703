import React, { ChangeEvent, useCallback, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import styled from 'styled-components';
import { PaymentReasonContainerProps } from '../../types/commons';
import PaymentReason from '../../components/Service/PaymentReason';
import PaymentCancelContainer from './PaymentCancelContainer';

const ModalWrap = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
  }
`;

const PaymentReasonContainer = ({
  isOpen,
  hotelCodeItem,
  confirmationNoItem,
  toggle,
  reload,
}: PaymentReasonContainerProps) => {
  const [reason, setReason] = useState<string>('');
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setReason(value);
  }, []);

  const handleSubmit = useCallback(() => {
    setCancelModal(true);
  }, []);

  const handleReload = () => {
    toggle();
    reload && reload();
  };

  return (
    <>
      <ModalWrap show={isOpen} onHide={() => toggle()} centered backdrop="static">
        <ModalBody className="m-3" style={{ margin: 0, padding: 0 }}>
          <PaymentReason
            reason={reason}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            toggle={toggle}
          />
        </ModalBody>
      </ModalWrap>
      {cancelModal && (
        <PaymentCancelContainer
          isOpen={cancelModal}
          hotelCodeItem={hotelCodeItem}
          confirmationNoItem={confirmationNoItem}
          reasonItem={reason}
          toggle={() => setCancelModal(!cancelModal)}
          reload={handleReload}
        />
      )}
    </>
  );
};

export default PaymentReasonContainer;
