import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../Commons/Table';
import { PaymentListProps } from '../../types/payment';
import { dateFormatter, priceFormatter, statusFormatter } from '../../lib/formatter';

const EditWrap = styled.div`
  width: 100%;
`;
const ListForm = styled.div`
  .form-button {
    margin-right: 0.5rem;
    margin-bottom: 12px;
    text-align: right;

    button {
      padding: 0.375rem 0;
      width: 120px;
      font-size: 13px;
      color: #ffffff;
      border: 0;
      border-radius: 3px;
      background: #481684;
      cursor: pointer;
    }
  }
  .form-table {
    border-radius: 5px;
    background: #ffffff;
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
`;
const TtopWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  .form-selectBox {
    margin-right: 1rem;
    margin-bottom: 20px;
    width: 17%;
    min-width: 183px;

    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }

  .form-date {
    display: flex;
    margin-bottom: 20px;
    padding: 0.375rem 1rem;
    width: 17%;
    min-width: 270px;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background: #ffffff;

    input {
      padding: 0.5rem;
      width: 100%;
      font-size: 0.875rem;
      color: #333333;
      line-height: 1.2;
      text-align: center;
      border: 0;
      border-radius: 0.18775rem;
      box-sizing: border-box;
      cursor: pointer;

      &:focus-visible {
        outline: 1.5px solid #481684;
      }
    }
  }

  .form-checkBox {
    display: flex;
    margin-bottom: 20px;
    margin-right: 2rem;
    font-size: 14px;
    align-items: center;

    label {
      display: flex;
      margin: 0;
      margin-left: 0.875rem;
      align-items: center;

      div {
        padding: 12px 8px;
        width: 70px;
        text-align: center;
        border-radius: 3px;
        background: #dddddd;
        cursor: pointer;

        &.check-on {
          color: #ffffff;
          background: #732ec5;
        }
      }
      input {
        position: absolute;
        opacity: 0;
      }
    }
  }
  .form-content-name {
    display: flex;
    margin-right: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: 17%;
    min-width: 183px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 0.3rem;
    background: #ffffff;

    input {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: 0px;

      :focus-visible {
        outline: none;
      }
    }
  }
  .list-search-btn {
    position: relative;
    top: 2px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);

      :hover {
        background: #732ec5;
      }
    }
  }
`;

const PaymentList = ({
  filterItems,
  paymentListItems,
  hotelCodeList,
  reservationStatusList,
  paymentStatusList,
  handleChange,
  handleDate,
  handleDateClick,
  handleFilter,
  handleFilterClean,
  handleExcelModal,
  handleDetailModal,
  handleCancelModal,
  handleSyncSaga,
}: PaymentListProps) => {
  return (
    <EditWrap>
      <ListForm>
        <TtopWrap>
          <div className="form-selectBox">
            <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
              <option value={''}>시설 선택</option>
              {hotelCodeList.map((item) => (
                <option key={item.detail} value={item.detail}>
                  {item.detailName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-content-name">
            <input
              type={'text'}
              name="confirmationNo"
              placeholder="Opera conf. #"
              value={filterItems.confirmationNo}
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input
              type={'text'}
              name="guestName"
              placeholder="투숙객 이름 (한글 명)"
              value={filterItems.guestName}
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input
              type={'text'}
              name="lastName"
              placeholder="예약자 성 (영문)"
              value={filterItems.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input
              type={'text'}
              name="firstName"
              placeholder="예약자 이름 (영문)"
              value={filterItems.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="form-selectBox">
            <select
              name="reservationStatus"
              value={filterItems.reservationStatus}
              onChange={handleChange}
            >
              <option value={''}>예약 상태</option>
              {reservationStatusList.map((item) => (
                <option key={item.detail} value={item.detail}>
                  {item.detailName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-selectBox">
            <select name="paymentStatus" value={filterItems.paymentStatus} onChange={handleChange}>
              <option value={''}>거래 상태</option>
              {paymentStatusList.map((item) => (
                <option key={item.detail} value={item.detail}>
                  {item.detailName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-date">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              placeholderText="시작 날짜"
              selectsStart
              selected={new Date(filterItems.startDate)}
              showTimeSelect={false}
              name="startDate"
              onChange={(date: Date) => handleDate(date, 'startDate')}
            />
            <span>~</span>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              placeholderText="종료 날짜"
              selectsEnd
              selected={new Date(filterItems.endDate)}
              showTimeSelect={false}
              minDate={new Date(filterItems.startDate)}
              name="endDate"
              onChange={(date: Date) => handleDate(date, 'endDate')}
            />
          </div>
          <div className="form-checkBox">
            <label>
              <input
                type={'checkbox'}
                name="dateType-day"
                onChange={() => handleDateClick('day')}
              />
              <div className={filterItems.dateType === 'day' ? 'check-on' : 'check-off'}>당일</div>
            </label>
            <label>
              <input
                type={'checkbox'}
                name="dateType-week"
                onChange={() => handleDateClick('week')}
              />
              <div className={filterItems.dateType === 'week' ? 'check-on' : 'check-off'}>
                일주일
              </div>
            </label>
            <label>
              <input
                type={'checkbox'}
                name="dateType-month"
                onChange={() => handleDateClick('month')}
              />
              <div className={filterItems.dateType === 'month' ? 'check-on' : 'check-off'}>
                한달
              </div>
            </label>
          </div>

          <div className="list-search-btn">
            <button onClick={handleFilter}>검색</button>
          </div>
          <div className="list-search-btn" style={{ marginLeft: '1rem' }}>
            <button onClick={handleFilterClean}>검색 초기화</button>
          </div>
        </TtopWrap>

        <div className="form-button">
          <button onClick={handleExcelModal}>Excel 다운로드</button>
        </div>
        <div className="form-table">
          <Table
            columns={[
              {
                key: 'hotelCodeText',
                text: '시설',
                width: '5%',
              },
              {
                key: 'confirmationNo',
                text: 'Opera conf. #',
                width: '4%',
              },
              {
                key: 'lastName',
                text: '예약자 성',
                width: '5%',
              },
              {
                key: 'firstName',
                text: '예약자 이름',
                width: '6%',
              },
              {
                key: 'guestName',
                text: '투숙객 한글명',
                width: '6%',
              },
              {
                key: 'reservationStatusText',
                text: '예약 상태',
                width: '4%',
              },
              {
                key: 'roomNumber',
                text: '객실 번호',
                width: '4%',
              },
              {
                key: 'arrivalDate',
                text: '체크인',
                width: '5%',
              },
              {
                key: 'departureDate',
                text: '체크아웃',
                width: '5%',
              },
              {
                key: 'cardName',
                text: '카드 종류',
                width: '5%',
              },
              {
                key: 'applyNum',
                text: '승인 번호',
                width: '10%',
              },
              {
                key: 'amount',
                text: '거래 금액 KRW',
                width: '8%',
                formatter: {
                  func: priceFormatter,
                  params: ['amount'],
                },
              },
              {
                key: 'paymentDate',
                text: '마지막 승인 일시',
                width: '10%',
                formatter: {
                  func: dateFormatter,
                  params: ['paymentDate'],
                },
              },
              {
                key: 'requestName',
                text: '요청자',
                width: '4%',
              },
              {
                key: 'channel',
                text: '승인 기기',
                width: '5%',
              },
              {
                key: 'pgType',
                text: 'PG 종류',
                width: '5%',
              },
              {
                key: 'status',
                text: '결제 상태',
                width: '4%',
                formatter: {
                  func: (paymentStatus: string, paymentStatusText: string) =>
                    statusFormatter(paymentStatus, paymentStatusText),
                  params: ['paymentStatus', 'paymentStatusText'],
                },
              },
              {
                key: 'button2',
                text: '',
                width: '10%',
                items: [
                  {
                    text: '결제 취소',
                    hidden: {
                      func: (paymentStatus: string) => paymentStatus !== 'paid',
                      params: ['paymentStatus'],
                    },
                    action: {
                      func: (hotelCode: string, confirmationNo: string) =>
                        handleCancelModal(hotelCode, confirmationNo),
                      params: ['hotelCode', 'confirmationNo'],
                    },
                  },
                  {
                    text: '동기화',
                    action: {
                      func: (hotelCode: string, confirmationNo: string) =>
                        handleSyncSaga(hotelCode, confirmationNo),
                      params: ['hotelCode', 'confirmationNo'],
                    },
                  },
                  {
                    text: '결제이력',
                    action: {
                      func: (hotelCode: string, confirmationNo: string) =>
                        handleDetailModal(hotelCode, confirmationNo),
                      params: ['hotelCode', 'confirmationNo'],
                    },
                  },
                ],
              },
            ]}
            datas={paymentListItems}
            bigSize={true}
          />
        </div>
      </ListForm>
    </EditWrap>
  );
};

export default PaymentList;
