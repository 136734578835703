import moment from 'moment';
import { ChangeEvent, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PaymentList from '../../components/Service/PaymentList';
import { useRefresh } from '../../lib/useRefresh';
import { RootState } from '../../stores';
import * as systemApi from '../../api/system';
import {
  changeField,
  getPaymentManagerListAction,
  initializeFilter,
  initialize,
  syncPaymentAction,
  changeResult,
  setPaginationItemAction,
  setFilterFailChange,
} from '../../stores/paymentList';
import { ResponseFailModal } from '../../components/Commons/ModalResponse';
import { initialize as initializeHeader } from '../../stores/header';
import PaymentReasonContainer from './PaymentReasonContainer';
import PaymentDetailContainer from './PaymentDetailContainer';
import ExcelDownloadContainer from './ExcelDownloadContainer';
import Pagination from '../../components/Commons/TablePagination';
import { useQuery } from '@tanstack/react-query';
import { PaymentListState } from '../../types/payment';

const CardForm = styled.div`
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
`;

const PaymentListContainer = () => {
  const dispatch = useDispatch();
  const {
    filterItems,
    filterItemsServerApi,
    paymentListItems,
    paymentListTotalCount,
    paginationItem,
    paymentListpageNum,
    paymentListError,
    paymentSyncSuccess,
    paymentSyncError,
  } = useSelector(({ paymentList }: RootState) => ({
    filterItems: paymentList.filterItems,
    filterItemsServerApi: paymentList.filterItemsServerApi,
    paymentListItems: paymentList.paymentListItems,
    paymentListTotalCount: paymentList.paymentListTotalCount,
    paymentListpageNum: paymentList.paymentListpageNum,
    paginationItem: paymentList.paginationItem,
    paymentListError: paymentList.paymentListError,
    paymentSyncSuccess: paymentList.paymentSyncSuccess,
    paymentSyncError: paymentList.paymentSyncError,
  }));
  const [hotelCodeList, setHotelCodeList] = useState<any[]>([]); //필터용 시설 목록
  const [paymentStatusList, setPaymentStatusList] = useState<any[]>([]); //필터용 거래 상태
  const [reservationStatusList, setReservationStatus] = useState<any[]>([]); //필터용 예약 상태
  const [paymentList, setPaymentList] = useState<any[]>([]); //결제 테이블 목록용
  const [hotelCodeItem, setHotelCodeItem] = useState<string>(''); //팝업에서 쓰일 호텔코드 값
  const [confirmationNoItem, setConfirmationNoItem] = useState<string>(''); //팝업에서 쓰일 오페라 정보
  const [excelModal, setExcelModal] = useState<boolean>(false); //엑셀 팝업 모달
  const [cancelModal, setCancelModal] = useState<boolean>(false); //결제 취소 팝업 모달
  const [detailModal, setDetailModal] = useState<boolean>(false); //결제 이력 팝업 모달
  const [popupMessage, setPopupMessage] = useState<string>(''); //팝업 메시지용
  const [errorModal, setErrorModal] = useState<boolean>(false); //에러 팝업용
  const [pagingFlag, setPagingFlag] = useState<boolean>(false); //페이지네이션 시 데이터 새로 넣을지에 대한 부분
  const [filterValue, setFilterValue] = useState<any>(filterItems); //검색 결과 없을 경우 이전 데이터를 쓰기 위한 백업용 필터 값
  const [paginationValue, setPaginationValue] = useState<number>(0); //검색 결과 없을 경우 이전 데이터 쓰기 위한 백업용 현재 페이지 번호
  const [cleanFlag, setCleanFlag] = useState<boolean>(true);

  //react-query
  useQuery(
    ['filterSelect'],
    () =>
      systemApi.getCommonCodeList({ enabled: true, headYn: false }).then((res) => {
        setHotelCodeList(res.data.data.codeList.filter((item: any) => item.head === 'HT001'));
        setReservationStatus(res.data.data.codeList.filter((item: any) => item.head === 'RS001'));
        setPaymentStatusList(res.data.data.codeList.filter((item: any) => item.head === 'ET001'));
      }),
    {
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler()),
    [],
  );

  const handleList = useCallback(
    (
      filterItemsServerApi: PaymentListState['filterItemsServerApi'],
      paginationItem: PaymentListState['paginationItem'],
    ) => {
      handleRefresh(() =>
        dispatch(getPaymentManagerListAction({ ...filterItemsServerApi, ...paginationItem })),
      );
    },
    [dispatch, handleRefresh],
  );

  const handleFilterClean = useCallback(() => {
    dispatch(initializeFilter());
    setCleanFlag(true);
  }, [dispatch]);

  //핸들링
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value, name } = e.target;

      dispatch(
        changeField({
          key: name,
          value: value,
        }),
      );
      setCleanFlag(false);
    },
    [dispatch],
  );

  const handleDate = useCallback(
    (date: Date, name: string) => {
      dispatch(
        changeField({
          key: name,
          value: moment(date).format('YYYY-MM-DD'),
        }),
      );
      setCleanFlag(false);
    },
    [dispatch],
  );

  const handleDateClick = useCallback(
    (type: string) => {
      let startDate = '';
      if (type === 'day') startDate = moment(filterItems.endDate).format('YYYY-MM-DD');
      if (type === 'week')
        startDate = moment(filterItems.endDate).add(-7, 'days').format('YYYY-MM-DD');
      if (type === 'month')
        startDate = moment(filterItems.endDate).add(-1, 'months').format('YYYY-MM-DD');

      if (filterItems.dateType === '' || filterItems.dateType !== type) {
        dispatch(
          changeField({
            key: 'dateType',
            value: type,
          }),
        );
        dispatch(
          changeField({
            key: 'startDate',
            value: startDate,
          }),
        );
        return;
      }
      dispatch(
        changeField({
          key: 'dateType',
          value: '',
        }),
      );
      setCleanFlag(false);
    },
    [dispatch, filterItems],
  );

  const handleExcelModal = () => {
    setExcelModal(true);
  };

  const handleDetailModal = useCallback((hotelCode: string, confirmationNo: string) => {
    setHotelCodeItem(hotelCode);
    setConfirmationNoItem(confirmationNo);
    setDetailModal(true);
  }, []);

  const handleCancelModal = useCallback((hotelCode: string, confirmationNo: string) => {
    setHotelCodeItem(hotelCode);
    setConfirmationNoItem(confirmationNo);
    setCancelModal(true);
  }, []);

  const handleSyncSaga = useCallback(
    (hotelCode: string, confirmationNo: string) => {
      handleRefresh(() => dispatch(syncPaymentAction({ hotelCode, confirmationNo })));
    },
    [dispatch, handleRefresh],
  );

  //페이지네이션 이동
  const changePagination = useCallback(
    (pageNumber: number) => {
      dispatch(
        setPaginationItemAction({
          paginationItem: {
            ...paginationItem,
            pageNum: pageNumber,
          },
        }),
      );
      setPagingFlag(true);
    },
    [dispatch, paginationItem],
  );

  const handleFilter = useCallback(() => {
    if (JSON.stringify(filterItems) === JSON.stringify(filterItemsServerApi)) {
      changePagination(1);
    } else {
      changePagination(paginationValue);
    }
  }, [changePagination, filterItems, filterItemsServerApi, paginationValue]);

  useEffect(() => {
    return () => {
      dispatch(initialize());
      handleList(filterItemsServerApi, paginationItem);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pagingFlag) {
      handleList(filterItemsServerApi, paginationItem);
      setPagingFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingFlag]);

  useEffect(() => {
    if (paymentListItems.length > 0 || paymentList.length === 0 || cleanFlag) {
      setPaymentList(paymentListItems);
      setFilterValue(filterItemsServerApi);
      setPaginationValue(paymentListpageNum);
    } else {
      setPopupMessage('검색 결과를 찾을 수 없습니다\n다시 검색하여 주세요.');
      setErrorModal(true);
      //dispatch(setFilterFailChange(filterValue));
      dispatch(
        setPaginationItemAction({
          paginationItem: {
            ...paginationItem,
            pageNum: paginationValue,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentListItems]);

  useEffect(() => {
    if (paymentSyncSuccess) {
      if (paymentSyncSuccess === '1000') {
        dispatch(changeResult({ key: 'paymentSyncSuccess', value: '' }));
        dispatch(changeResult({ key: 'paymentSyncError', value: null }));
        handleList(filterItemsServerApi, paginationItem);
      }
    }
    if (paymentSyncError) {
      const resultCode = paymentSyncError.response?.data?.resultCode;
      if (
        resultCode === 'MC003' ||
        resultCode === 'MC004' ||
        resultCode === 'MC005' ||
        resultCode === 'MC006' ||
        resultCode === 'MC007' ||
        resultCode === 'MC008'
      ) {
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if (resultCode === '9999') {
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({ key: 'paymentSyncSuccess', value: '' }));
      dispatch(changeResult({ key: 'paymentSyncError', value: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSyncSuccess, paymentSyncError]);

  useEffect(() => {
    if (paymentListError) {
      const resultCode = paymentListError.response?.data?.resultCode;
      if (
        resultCode === 'MC003' ||
        resultCode === 'MC004' ||
        resultCode === 'MC005' ||
        resultCode === 'MC006' ||
        resultCode === 'MC007' ||
        resultCode === 'MC008'
      ) {
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if (resultCode === '9999') {
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({ key: 'paymentListError', value: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentListError]);

  return (
    <>
      <CardForm>
        <PaymentList
          filterItems={filterItems}
          paymentListItems={paymentList}
          hotelCodeList={hotelCodeList}
          reservationStatusList={reservationStatusList}
          paymentStatusList={paymentStatusList}
          handleChange={handleChange}
          handleDate={handleDate}
          handleDateClick={handleDateClick}
          handleFilter={handleFilter}
          handleFilterClean={handleFilterClean}
          handleExcelModal={handleExcelModal}
          handleDetailModal={handleDetailModal}
          handleCancelModal={handleCancelModal}
          handleSyncSaga={handleSyncSaga}
        />
      </CardForm>
      <Pagination
        total={paymentListTotalCount}
        index={paginationItem.pageNum}
        limit={paginationItem.pageSize}
        indexChange={changePagination}
      />
      {errorModal && (
        <ResponseFailModal
          isOpen={errorModal}
          toggle={() => setErrorModal(!errorModal)}
          message={popupMessage}
        />
      )}
      {cancelModal && (
        <PaymentReasonContainer
          isOpen={cancelModal}
          hotelCodeItem={hotelCodeItem}
          confirmationNoItem={confirmationNoItem}
          toggle={() => setCancelModal(!cancelModal)}
          reload={() => handleList(filterItemsServerApi, paginationItem)}
        />
      )}
      {detailModal && (
        <PaymentDetailContainer
          isOpen={detailModal}
          hotelCodeItem={hotelCodeItem}
          confirmationNoItem={confirmationNoItem}
          toggle={() => setDetailModal(!detailModal)}
        />
      )}
      {excelModal && (
        <ExcelDownloadContainer isOpen={excelModal} toggle={() => setExcelModal(!excelModal)} />
      )}
    </>
  );
};

export default PaymentListContainer;
